.chart {
 height:700px;
 width:1200px;
 background: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
   
}

.chart:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}